import { createNanoEvents } from 'nanoevents';

export class EventBus {
  emitter;
  eventTypes;

  constructor(eventTypes) {
    EventBus._guardAgainstEmptyEventTypes(eventTypes);
    this.eventTypes = eventTypes;
    this.emitter = createNanoEvents();
  }

  emit(event, payload) {
    EventBus._guardAgainstEmptyEvent(event, 0);
    this.emitter.emit(event, payload);
  }

  on(event, callback) {
    EventBus._guardAgainstEmptyEvent(event, 1);
    this.emitter.on(event, callback);
  }

  static _guardAgainstEmptyEventTypes(eventTypes) {
    if (!eventTypes || !Object.values(eventTypes)) {
      throw new Error('eventTypes arg is required');
    }
  }

  static _guardAgainstEmptyEvent(event, errorIndex) {
    const isString = typeof event === 'string';
    const baseMsg = 'event arg is required';

    if (!event) {
      const errors = [
        `${baseMsg}: cannot emit empty event`,
        `${baseMsg}: cannot subscribe to empty event`,
      ];

      throw new Error(errors[errorIndex]);
    }

    if (!isString) {
      throw new Error(`${baseMsg}: cannot be not a string type`);
    }
  }
}
