<template>
  <app-page>
    <module-payments />
  </app-page>
</template>

<script>
import { AppPage } from '@/components/app-page';

export default {
  name: 'payments-page',
  components: {
    AppPage,
  },
};
</script>
