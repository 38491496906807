import { EventBus } from './event-bus';

export default class ErrorBusService extends EventBus {
  static _EVENT_ERROR = 'EVENT_ERROR';

  constructor() {
    super({
      [ErrorBusService._EVENT_ERROR]: ErrorBusService._EVENT_ERROR,
    });
  }

  raiseError(e, opts) {
    this.emit(ErrorBusService._EVENT_ERROR, {
      e,
      opts,
    });
  }

  listenErrors(errorHandler) {
    this.on(ErrorBusService._EVENT_ERROR, errorHandler);
  }
}
