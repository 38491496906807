<template>
  <v-app id="vuetify-app">
    <app v-if="isAppSuccessfullyInit" />
    <app-error v-if="isAppFailedInit" />
    <app-stub v-if="isAppInitializing" />
  </v-app>
</template>

<script>
import { facadeGlobal } from '@/mixins';
import { APP_INIT_STATES } from '@/constants';

import { App } from '@/components/app';
import { AppError } from '@/components/app-error';
import { AppStub } from '@/components/app-stub';

export default {
  name: 'c-app',
  components: {
    App,
    AppError,
    AppStub,
  },
  mixins: [facadeGlobal],
  inject: ['accessToken'],
  created() {
    this.initApp(this.accessToken);
  },
  computed: {
    isAppSuccessfullyInit() {
      return this.appInitState === APP_INIT_STATES.SUCCESS;
    },
    isAppFailedInit() {
      return this.appInitState === APP_INIT_STATES.FAIL;
    },
    isAppInitializing() {
      return this.appInitState === APP_INIT_STATES.IN_PROCESS;
    },
  },
};
</script>
