import moduleRates from '@/modules/rates';
import moduleRatesManagement from '@/modules/rates-management';
import modulePaymentSystems from '@/modules/payment-systems';
import modulePaymentRates from '@/modules/payment-rates';
import modulePayments from '@/modules/payments';
import moduleUsersManagement from '@/modules/users-management';

export default function (Vue, context) {
  const featureModules = {
    moduleRates: moduleRates.module,
    moduleRatesManagement: moduleRatesManagement.module,
    modulePaymentSystems: modulePaymentSystems.module,
    modulePaymentRates: modulePaymentRates.module,
    modulePayments: modulePayments.module,
    moduleUsersManagement: moduleUsersManagement.module,
  };

  Object
    .entries(featureModules)
    .forEach(([moduleName, module]) => {
      module.register({ moduleName, Vue, context });
    });
}
