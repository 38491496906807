import { AppModule } from '@amarkets/atlas/esm/lib/app-module';
import { PropsValidator } from '@amarkets/atlas/esm/lib/props-validator';
import { STORE_MODULE_RATES_MANAGEMENT } from './store/types';
import { MANAGEMENT_TYPES } from './constants';

const rootComponent = () => import(
  /* webpackChunkName: "module-rates-management" */
  './components/module-root.vue'
);
const storeModule = () => import(
  /* webpackChunkName: "module-rates-management-store" */
  './store'
);

const props = {
  isVisible: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: true,
    validator: PropsValidator.isIncludeValues([
      ...Object.values(MANAGEMENT_TYPES),
      '',
    ], 'type'),
  },
  symbols: {
    type: Array,
    default: () => [],
  },
};

const propsProviderFactory = (moduleRoot) => ({
  getModuleVisibility: () => moduleRoot.isVisible,
  getManagementType: () => moduleRoot.type,
  getSymbols: () => moduleRoot.symbols,
});

const eventsFactory = (moduleRoot) => ({
  moduleChangeRateCommission(symbol) {
    moduleRoot.$emit('change-rate-commission', symbol);
  },
  moduleHide() {
    moduleRoot.$emit('hide-module');
  },
  moduleInput(event) {
    moduleRoot.$emit('input', event);
  },
});

export default {
  module: new AppModule(rootComponent, {
    props,
    storeAsyncFactory: storeModule,
    storeModuleName: STORE_MODULE_RATES_MANAGEMENT,
    eventsFactory,
    propsProviderFactory,
  }),
};
