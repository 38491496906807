// module name
export const STORE_MODULE_PAYMENT_SYSTEMS = 'STORE_MODULE_PAYMENT_SYSTEMS';

// getters
export const GETTER_PAYMENT_SYSTEMS = 'GETTER_PAYMENT_SYSTEMS';
export const GETTER_COUNTRIES = 'GETTER_COUNTRIES';
export const GETTER_CURRENCIES = 'GETTER_CURRENCIES';
export const GETTER_PAYMENT_METHODS = 'GETTER_PAYMENT_METHODS';
export const GETTER_IS_LOADING = 'GETTER_IS_LOADING';

// actions
export const ACTION_FETCH_PAYMENT_SYSTEMS = 'ACTION_FETCH_PAYMENT_SYSTEMS';
export const ACTION_CREATE_PAYMENT_SYSTEMS = 'ACTION_CREATE_PAYMENT_SYSTEMS';
export const ACTION_UPDATE_PAYMENT_SYSTEMS = 'ACTION_UPDATE_PAYMENT_SYSTEMS';
export const ACTION_FETCH_COUNTRIES = 'ACTION_FETCH_COUNTRIES';
export const ACTION_FETCH_CURRENCIES = 'ACTION_FETCH_CURRENCIES';
export const ACTION_FETCH_PAYMENT_METHODS = 'ACTION_FETCH_PAYMENT_METHODS';
export const ACTION_SET_STATE_TO_DEFAULT = 'ACTION_SET_STATE_TO_DEFAULT';

// mutations
export const MUTATION_SET_PAYMENT_SYSTEMS = 'MUTATION_SET_PAYMENT_SYSTEMS';
export const MUTATION_SET_PAYMENT_METHODS = 'MUTATION_SET_PAYMENT_METHODS';
export const MUTATION_SET_CURRENCIES = 'MUTATION_SET_CURRENCIES';
export const MUTATION_SET_COUNTRIES = 'MUTATION_SET_COUNTRIES';
export const MUTATION_SET_IS_LOADING = 'MUTATION_SET_IS_LOADING';
