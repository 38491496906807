export default new Map([
  [401, 'Not authorized'],
  [403, 'Permission denied'],
  [429, 'Too many wrong login attempts'],
  [404, 'Resource is not found'],
  [500, 'Something went wrong. Please, try again later'],
  [502, 'Bad gateway. Please, try again later'],
  [undefined, 'Server is not available. Please, try again later'],
  ['default', 'Something went wrong... Please, try again later'],
]);
