import Vue from 'vue';
import Vuetify, {
  VBtn,
  VIcon,
  VProgressCircular,
  VCol,
  VContainer,
  VRow,
  VSelect,
  VTextField,
  VBottomNavigation,
  VAppBar,
  VNavigationDrawer,
  VTabs,
  VTab,
  VFadeTransition,
  VDataTable,
  VCheckbox,
  VPagination,
  VChip,
  VAutocomplete,
  VImg,
  VTooltip,
  VDialog,
} from 'vuetify/lib';
import { icons, themes } from '@amarkets/ui-kit/dist/ui-kit.common';

const { main: { options, themes: { light } } } = themes;

Vue.use(Vuetify, {
  components: {
    VBtn,
    VIcon,
    VProgressCircular,
    VCol,
    VContainer,
    VRow,
    VSelect,
    VTextField,
    VBottomNavigation,
    VAppBar,
    VNavigationDrawer,
    VTabs,
    VTab,
    VFadeTransition,
    VDataTable,
    VCheckbox,
    VPagination,
    VChip,
    VAutocomplete,
    VImg,
    VTooltip,
    VDialog,
  },
});

export default function createVuetify() {
  return new Vuetify({
    theme: {
      options,
      themes: {
        light: {
          ...light,
          anchor: light.black.base,
        },
      },
    },
    icons,
  });
}
