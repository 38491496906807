import { VuexHelper } from '@amarkets/atlas/esm/lib/vuex-helper';
import { STORE_MODULE_GLOBAL } from '@/plugins/store/modules/global/types';
import { STORE_MODULE_PAYMENT_SYSTEMS } from '@/plugins/store/modules/payment-systems/types';
import { STORE_MODULE_SYMBOLS } from '@/plugins/store/modules/symbols/types';

import createGlobalModule from '@/plugins/store/modules/global';
import createPaymentSystemsModule from '@/plugins/store/modules/payment-systems';
import createSymbolsModule from '@/plugins/store/modules/symbols';

export default function (context) {
  const storeFactories = {
    [STORE_MODULE_GLOBAL]: createGlobalModule,
    [STORE_MODULE_PAYMENT_SYSTEMS]: createPaymentSystemsModule,
    [STORE_MODULE_SYMBOLS]: createSymbolsModule,
  };

  Object
    .entries(storeFactories)
    .forEach(([moduleName, createStoreModule]) => {
      VuexHelper.registerStoreModule({
        store: context.store,
        module: createStoreModule(context),
        moduleName,
      });
    });
}
