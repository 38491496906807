import { AppModule } from '@amarkets/atlas/esm/lib/app-module';
import { PropsValidator } from '@amarkets/atlas/esm/lib/props-validator';
import { moduleApi } from './api';
import { STORE_MODULE_SYMBOL_RATES } from './store/types';
import { VIEW_DATA_TYPES } from './constants';

const rootComponent = () => import(
  /* webpackChunkName: "module-rates" */
  './components/module-root.vue'
);
const storeModule = () => import(
  /* webpackChunkName: "module-rates-store" */
  './store'
);

const props = {
  viewDataType: {
    type: String,
    default: VIEW_DATA_TYPES.HISTORY,
    validator: PropsValidator.isIncludeValues(Object.values(VIEW_DATA_TYPES), 'type'),
  },
  symbols: {
    type: Array,
    default: () => [],
  },
};

const propsProviderFactory = (moduleRoot) => ({
  getViewDataType: () => moduleRoot.viewDataType,
  getSymbols: () => moduleRoot.symbols,
});

const eventsFactory = (moduleRoot) => ({
  clickChangeRatesCommission() {
    moduleRoot.$emit('click-change-rate-commission');
  },
});

export default {
  module: new AppModule(rootComponent, {
    props,
    storeAsyncFactory: storeModule,
    storeModuleName: STORE_MODULE_SYMBOL_RATES,
    eventsFactory,
    propsProviderFactory,
  }),
  api: moduleApi,
};
