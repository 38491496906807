import { APP_ROUTES } from '@/constants';

const UsersPage = () => import(
  /* webpackChunkName: "users-management-page" */
  '@/views/users-management/users-management-page.vue'
);

export const usersManagementRoutes = {
  path: APP_ROUTES.USERS.PATH,
  name: APP_ROUTES.USERS.NAME,
  component: UsersPage,
  children: [
    {
      path: APP_ROUTES.USER_DETAILS.PATH,
      name: APP_ROUTES.USER_DETAILS.NAME,
      component: () => import('@/modules/users-management/views/user-details-page.vue'),
    },
  ],
};
