import { createNamespacedHelpers } from 'vuex';

/**
 * Returns namespaced vuex getters and actions
 */

export default function (namespace, mixinFactory) {
  const {
    mapGetters,
    mapActions,
  } = createNamespacedHelpers(namespace);

  return mixinFactory({
    mapGetters,
    mapActions,
  });
}
