import { AppModule } from '@amarkets/atlas/esm/lib/app-module';

const rootComponent = () => import(
  /* webpackChunkName: "module-payment-systems" */
  './components/module-root/module-root.vue'
);

const props = {
  paymentSystems: {
    type: Array,
    default: () => [],
  },
  paymentMethods: {
    type: Array,
    default: () => [],
  },
  currencies: {
    type: Array,
    default: () => [],
  },
  countries: {
    type: Array,
    default: () => [],
  },
};

const propsProviderFactory = (moduleRoot) => ({
  getPaymentSystems: () => moduleRoot.paymentSystems,
  getPaymentMethods: () => moduleRoot.paymentMethods,
  getCurrencies: () => moduleRoot.currencies,
  getCountries: () => moduleRoot.countries,
});

const eventsFactory = (moduleRoot) => ({
  eventRedirectPaymentRates(paymentSystemUid) {
    moduleRoot.$emit('redirect-payment-rates', paymentSystemUid);
  },
  eventUpdatePaymentSystem(payload) {
    moduleRoot.$emit('update-payment-system', payload);
  },
  eventCreatePaymentSystem(payload) {
    moduleRoot.$emit('create-payment-system', payload);
  },
});

export default {
  module: new AppModule(rootComponent, {
    props,
    eventsFactory,
    propsProviderFactory,
  }),
};
