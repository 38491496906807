import {
  ACTION_GET_AVAILABLE_SYMBOLS,
  MUTATION_SET_AVAILABLE_SYMBOLS,
  GETTERS_AVAILABLE_SYMBOLS,
} from './types';

export default function ({
  getAvailableSymbols,
}) {
  return {
    async [ACTION_GET_AVAILABLE_SYMBOLS]({ commit, getters }) {
      const availableSymbols = getters[GETTERS_AVAILABLE_SYMBOLS];
      const save = (data) => commit(MUTATION_SET_AVAILABLE_SYMBOLS, data);

      await getAvailableSymbols.call(save, availableSymbols);
    },
  };
}
