import Vue from 'vue';
// https://github.com/euvl/vue-notification
import Notifications from 'vue-notification';
import { UiNotifier } from '@amarkets/atlas/esm/lib/ui-notifier';

// to call this instance inside vue component use this.$notify
Vue.use(Notifications);

export default function createUiNotifier() {
  return new UiNotifier(Vue);
}
