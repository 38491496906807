import Vue from 'vue';
import { CApp } from '@/containers/c-app';
import { CONFIG } from '@/../config';
import bootstrap from '@/bootstrap';

Vue.config.productionTip = false;

const {
  // errorBus,
  store,
  router,
  vuetify,
  tokenSyncService,
  facadePaymentSystems,
} = bootstrap({ appConfig: CONFIG });

const app = new Vue({
  router,
  store,
  vuetify,
  provide() {
    return {
      accessToken: tokenSyncService.tokens.accessToken,
      facadePaymentSystems,
    };
  },
  render: (h) => h(CApp),
});

// TODO: need to listen errors and react and handle or smth
// errorBus.listenErrors((a) => {
//   console.info(a);
// });

app.$mount('#app');
