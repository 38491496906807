import { APP_ROUTES } from '@/constants';
import RatesRootPage from '@/views/rates/root-page/root-page.vue';

const RatesCurrentPage = () => import(
  /* webpackChunkName: "rates-current-page" */
  '@/views/rates/current-page.vue'
);
const RatesHistoryPage = () => import(
  /* webpackChunkName: "rates-history-page" */
  '@/views/rates/history-page.vue'
);

export const rateRoutes = {
  path: APP_ROUTES.RATES.PATH,
  name: '',
  component: RatesRootPage,
  children: [
    {
      path: '',
      name: APP_ROUTES.RATES.NAME,
      component: RatesCurrentPage,
    },
    {
      path: APP_ROUTES.RATES_HISTORY.PATH,
      name: APP_ROUTES.RATES_HISTORY.NAME,
      component: RatesHistoryPage,
    },
  ],
};
