<template>
  <div class="layout-main">
    <app-navigation v-model="drawer" />
    <c-app-bar @toggle-icon-click="drawer = !drawer" />
    <v-main class="layout-main__content">
      <router-view />
    </v-main>
  </div>
</template>

<script>
import { AppNavigation } from '@/components/app-navigation';
import { CAppBar } from '@/containers/c-app-bar';

export default {
  name: 'layout-main',
  components: {
    CAppBar,
    AppNavigation,
  },
  data: () => ({
    drawer: null,
  }),
};
</script>

<style lang="scss" scoped>
  @import 'layout-main';
</style>
