export default class PaymentService {
  _paymentSystems;
  _paymentMethods;
  _currencies;
  _countries;
  _processingApi;
  _raiseError;

  constructor({ processingApi, raiseError }) {
    this._paymentSystems = [];
    this._paymentMethods = [];
    this._currencies = [];
    this._countries = [];
    this._processingApi = processingApi;
    this._raiseError = raiseError;
  }

  get paymentSystems() {
    return this._paymentSystems;
  }

  get paymentMethods() {
    return this._paymentMethods;
  }

  get currencies() {
    return this._currencies;
  }

  get countries() {
    return this._countries;
  }

  async getPaymentSystems() {
    try {
      this._paymentSystems = await this._processingApi.getPaymentSystems((data) => data?.data.attributes);
    } catch (e) {
      console.error(e, 'PaymentService: getPaymentSystems');
      this._raiseError(e);
    }
  }

  async getPaymentMethods() {
    try {
      this._paymentMethods = await this._processingApi.getPaymentMethods((data) => data?.data.attributes);
    } catch (e) {
      console.error(e, 'ProcessingService: getPaymentMethods');
      this._raiseError(e);
    }
  }

  async getCurrencies() {
    try {
      this._currencies = await this._processingApi.getCurrencies((data) => data?.data.attributes);
    } catch (e) {
      console.error(e, 'ProcessingService: getCurrencies');
      this._raiseError(e);
    }
  }

  async getCountries() {
    try {
      this._countries = await this._processingApi.getCountries((data) => data?.data.attributes);
    } catch (e) {
      console.error(e, 'ProcessingService: getCountries');
      this._raiseError(e);
    }
  }

  async updatePaymentSystem(payload) {
    try {
      await this._processingApi.updatePaymentSystem(payload);
    } catch (e) {
      console.error(e, 'ProcessingService: updatePaymentSystem');
      this._raiseError(e);
    }
  }

  async createPaymentSystem(payload) {
    try {
      await this._processingApi.createPaymentSystem(payload);
    } catch (e) {
      console.error(e, 'ProcessingService: createPaymentSystem');
      this._raiseError(e);
    }
  }
}
