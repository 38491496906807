import { HttpClient } from '@amarkets/atlas/esm/lib/http-client';
import { AuthApi } from '@amarkets/atlas/esm/lib/auth-api';
import { httpInterceptorsService, TokenService } from '@/services';

export default function ({
  accessToken,
  refreshToken,
  appConfig,
  onTokenUpdate,
}) {
  const tokenService = new TokenService({
    authApi: new AuthApi(new HttpClient(), appConfig.API_URL.AUTH),
    onTokenUpdate,
    accessToken,
    refreshToken,
  });

  return new HttpClient({
    timeout: 20000,
    reqInterceptorsSuccess: [
      httpInterceptorsService.injectAuthHeader(() => tokenService.accessToken),
    ],
    onTokenExpired: tokenService.updateToken.bind(tokenService),
  });
}
