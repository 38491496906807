import { withVuexNamespace } from '@/lib/utils';
import {
  STORE_MODULE_PAYMENT_SYSTEMS,
  ACTION_FETCH_PAYMENT_SYSTEMS,
  GETTER_PAYMENT_SYSTEMS,
  GETTER_IS_LOADING,
  ACTION_CREATE_PAYMENT_SYSTEMS,
  ACTION_UPDATE_PAYMENT_SYSTEMS,
  ACTION_FETCH_PAYMENT_METHODS,
  ACTION_FETCH_CURRENCIES,
  ACTION_SET_STATE_TO_DEFAULT,
  ACTION_FETCH_COUNTRIES,
  GETTER_PAYMENT_METHODS,
  GETTER_CURRENCIES,
  GETTER_COUNTRIES,
} from '@/plugins/store/modules/payment-systems/types';

const factory = ({ mapGetters, mapActions }) => ({
  computed: {
    ...mapGetters({
      getPaymentSystems: GETTER_PAYMENT_SYSTEMS,
      getPaymentMethods: GETTER_PAYMENT_METHODS,
      getCurrencies: GETTER_CURRENCIES,
      getCountries: GETTER_COUNTRIES,
      isLoading: GETTER_IS_LOADING,
    }),
  },
  methods: {
    ...mapActions({
      fetchPaymentSystems: ACTION_FETCH_PAYMENT_SYSTEMS,
      fetchPaymentMethods: ACTION_FETCH_PAYMENT_METHODS,
      fetchCurrencies: ACTION_FETCH_CURRENCIES,
      fetchCountries: ACTION_FETCH_COUNTRIES,
      updatePaymentSystem: ACTION_UPDATE_PAYMENT_SYSTEMS,
      createPaymentSystem: ACTION_CREATE_PAYMENT_SYSTEMS,
      setStateToDefault: ACTION_SET_STATE_TO_DEFAULT,
    }),
  },
});

export default withVuexNamespace(STORE_MODULE_PAYMENT_SYSTEMS, factory);
