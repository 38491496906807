import { QueryParamsTranslator } from '@amarkets/atlas/esm/lib/query-params-translator';
import { AuthApi } from '@amarkets/atlas/esm/lib/auth-api';
import { ExchangeApi } from '@amarkets/atlas/esm/lib/exchange-api';
import { ProcessingApi } from '@amarkets/atlas/esm/lib/processing-api';

export default function (httpClient, appConfig) {
  return {
    authApi: new AuthApi(httpClient, appConfig.API_URL.AUTH),
    exchangeApi: new ExchangeApi(httpClient, appConfig.API_URL.EXCHANGE, QueryParamsTranslator),
    processingApi: new ProcessingApi(httpClient, appConfig.API_URL.PROCESSING),
  };
}
