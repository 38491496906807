import { rateRoutes } from './rates';
import { paymentSystemsRoutes } from './payment-systems';
import { paymentRatesRoutes } from './payment-rates';
import { noAccessRoute } from './no-access';
import { paymentsRoutes } from './payments';
import { usersManagementRoutes } from './users-management';

export default [
  rateRoutes,
  paymentSystemsRoutes,
  paymentRatesRoutes,
  noAccessRoute,
  paymentsRoutes,
  usersManagementRoutes,
];
