<template>
  <div class="app-error">
    Something went wrong please try again later
  </div>
</template>

<script>
export default {
  name: 'app-error',
};
</script>

<style lang="scss" scoped>
  @import 'app-error';
</style>
