export const CONFIG = {
  ENV: process.env.NODE_ENV,
  PUBLIC_PATH: process.env.VUE_APP_PUBLIC_PATH,
  ROUTER_URL: process.env.VUE_APP_ROUTER_URL,
  API_URL: {
    AUTH: process.env.VUE_APP_API_URL_AUTH,
    EXCHANGE: process.env.VUE_APP_API_URL_EXCHANGE,
    PROCESSING: process.env.VUE_APP_API_URL_PROCESSING,
  },
};
