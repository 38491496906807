<template>
  <app-bar
    :authorized-user="authorizedUser"
    @toggle-icon-click="$emit('toggle-icon-click')"
  />
</template>

<script>
import { facadeGlobal } from '@/mixins';

import { AppBar } from '@/components/app-bar';

export default {
  name: 'c-app-bar',
  components: {
    AppBar,
  },
  mixins: [facadeGlobal],
};
</script>
