<template>
  <app-page :loading="isLoading">
    <module-payment-systems
      :payment-systems="getPaymentSystems"
      :payment-methods="getPaymentMethods"
      :currencies="getCurrencies"
      :countries="getCountries"
      :is-loading="isLoading"
      @redirect-payment-rates="redirectPaymentRates"
      @update-payment-system="updatePaymentSystemHandler"
      @create-payment-system="createPaymentSystemHandler"
    />
  </app-page>
</template>

<script>
import { AppPage } from '@/components/app-page';
import { APP_ROUTES } from '@/constants';
import { facadePaymentSystems } from '@/mixins';

export default {
  name: 'payment-systems-page',
  components: {
    AppPage,
  },
  mixins: [facadePaymentSystems],
  mounted() {
    Promise.all([
      this.fetchPaymentSystems(),
      this.fetchPaymentMethods(),
      this.fetchCountries(),
      this.fetchCurrencies(),
    ]);
  },
  beforeDestroy() {
    this.setStateToDefault();
  },
  methods: {
    redirectPaymentRates(paymentSystemUid) {
      this.$router.push({ name: APP_ROUTES.PAYMENT_RATES.NAME, query: { paymentSystemUid } });
    },
    async updatePaymentSystemHandler(payload) {
      await this.updatePaymentSystem(payload);
      await this.fetchPaymentSystems();
    },
    async createPaymentSystemHandler(payload) {
      await this.createPaymentSystem(payload);
      await this.fetchPaymentSystems();
    },
  },
};
</script>
