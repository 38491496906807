import { CONFIG } from '../../config';

const handleLinks = (links = {}) => {
  const isProduction = CONFIG.ENV === 'production';

  return Object
    .entries(links)
    .reduce((acc, [key, link]) => {
      acc[key] = isProduction ? link.replace('abc', 'my') : link;
      return acc;
    }, {});
};

export default {
  SPACES: handleLinks({
    CLIENT: 'https://abc.amarkets.org/trader',
    MANAGER: 'https://abc.amarkets.org/manager',
    PARTNERSHIP_MANAGER: 'https://abc.amarkets.org/manager_pp/active_partners',
    PARTNERSHIP_ADMIN: 'https://abc.amarkets.org/admin_pp',
    DEALING: 'https://abc.amarkets.org/dealing',
    MARKETING: 'https://abc.amarkets.org/marketing',
  }),
  FINANCIAL_OPERATIONS: handleLinks({
    REQUEST_FOR_PAYMENTS: 'https://abc.amarkets.org/admin/payments',
    REQUEST_FOR_WITHDRAWALS: 'https://abc.amarkets.org/admin/withdrawals',
    TRANSACTION_REPORTS: 'https://abc.amarkets.org/admin/operations_history',
    FINANCIAL_DIAGRAMS: 'https://abc.amarkets.org/admin/financial_diagrams',
    PAYMENT_SYSTEM_REPORTS: 'https://abc.amarkets.org/admin/payment_systems_reports',
    PAYMENT_SYSTEM_REPORTS2: 'https://abc.amarkets.org/admin/ps_reports',
    DAILY_STATS: 'https://abc.amarkets.org/admin/daily_stats',
    FINISH_WITHDRAWALS_REPORT: 'https://abc.amarkets.org/admin/finished_withdrawals_report',
  }),
  REMUNERATION_RULES: handleLinks({
    TEMPLATE_RULES: 'https://abc.amarkets.org/admin/rule_templates',
    REWARDS_RATES_IB: 'https://abc.amarkets.org/admin/partner_rates',
    REWARDS_RATES_WEB: 'https://abc.amarkets.org/admin/partner_web_templates',
  }),
  REWARDS: handleLinks({
    PARTNER: 'https://abc.amarkets.org/admin/partner_rewards',
    PARTNER_BONUSES: 'https://abc.amarkets.org/admin/partner_bonuses',
    MANAGE_OF_BONUS_ORDERS: 'https://abc.amarkets.org/admin/bonus_orders',
    MANAGE_OF_WELCOME_BONUSES: 'https://abc.amarkets.org/admin/welcome_bonuses',
    MANAGE_OF_INSURANCE_BONUSES: 'https://abc.amarkets.org/admin/insurance_bonuses',
  }),
  REPORTS: handleLinks({
    WITHDRAWALS: 'https://abc.amarkets.org/admin/daily_withdrawals_reports',
    MANAGE_OF_BONUS_ORDERS: 'https://abc.amarkets.org/admin/payment_accounts',
    FIRST_REPLENISHMENT: 'https://abc.amarkets.org/admin/payment_accounts',
    FIRST_REPLENISHMENT_FOR_100: 'https://abc.amarkets.org/admin/marketing_motivations',
    ANALYST_MOTIVATION_REPORTS: 'https://abc.amarkets.org/manager/analyst/motivation_volume_reports',
    CONVERSIONS: 'https://abc.amarkets.org/admin/conversions',
    CONVERSION_OPERATIONS: 'https://abc.amarkets.org/admin/conversion_operations',
    COMMON_GLASSES: 'https://abc.amarkets.org/admin/common_glasses',
    MANAGERS_RATING: 'https://abc.amarkets.org/admin/manager_rating',
    MANAGERS_RATING_SETTINGS: 'https://abc.amarkets.org/admin/settings_manager_rating',
    LOW_PROFITABILITY_OF_WITHDRAWALS: 'https://abc.amarkets.org/admin/low_probability_of_withdrawals',
    LOYALTY_BONUS_REWARDS: 'https://abc.amarkets.org/admin/bonus_loyalty_rewards',
    NO_BONUS_REWARDS: 'https://abc.amarkets.org/admin/no_deposit_reports',
  }),
  FINANCIAL_SYSTEM: handleLinks({
    FIN_SYSTEM: 'https://abc.amarkets.org/admin/finance_core',
    MOVEMENT_OF_FUNDS: 'https://abc.amarkets.org/admin/common_flow_funds',
    BITCOIN_REPORTS: 'https://abc.amarkets.org/admin/bitcoin_flow_funds',
    DDF_DIFFS: 'https://abc.amarkets.org/admin/common_account_diffs',
    BONUS_FLOW_REPORT: 'https://abc.amarkets.org/admin/bonus_flow_report',
    CLIENTS_ONLINE: 'https://abc.amarkets.org/admin/online_clients',
    WITHDRAWAL_COMMISSION_FUNDS: 'https://abc.amarkets.org/admin/withdrawal_commission_funds',
  }),
  REQUESTS: handleLinks({
    VERIFICATIONS: 'https://abc.amarkets.org/admin/verifications',
    PS_VERIFICATION: 'https://abc.amarkets.org/admin/verification_pses',
    PS_VERIFICATION_SPEED: 'https://abc.amarkets.org/admin/verification_ps_speed',
    BONUS_VERIFICATIONS: 'https://abc.amarkets.org/admin/bonus_verifications',
    VERIFICATIONS_TRAFFIC_LIGHT: 'https://abc.amarkets.org/admin/verification_traffic_light',
    VERIFICATION_SPEED: 'https://abc.amarkets.org/admin/verification_speed',
    COMPENSATIONS: 'https://abc.amarkets.org/manager/commission_compensations',
  }),
};
