import { withVuexNamespace } from '@/lib/utils';
import {
  STORE_MODULE_GLOBAL,
  ACTION_INIT_APP,
  ACTION_LOGOUT_USER,
  GETTER_APP_INIT_STATE,
  GETTER_AUTH_USER,
} from '@/plugins/store/modules/global/types';

const factory = ({ mapGetters, mapActions }) => ({
  computed: {
    ...mapGetters({
      appInitState: GETTER_APP_INIT_STATE,
      authorizedUser: GETTER_AUTH_USER,
    }),
  },
  methods: {
    ...mapActions({
      initApp: ACTION_INIT_APP,
      logoutUser: ACTION_LOGOUT_USER,
    }),
  },
});

export default withVuexNamespace(STORE_MODULE_GLOBAL, factory);
