export class Symbol {
  _symbol;

  constructor(opts) {
    this._symbol = opts.symbol;
  }

  get symbolBase() {
    return this._symbol;
  }

  get symbolInLowerCase() {
    return this._symbol.toLowerCase();
  }
}
