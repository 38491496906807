import packageInfo from '../../package.json';

export default {
  APP_RELEASE: `${packageInfo.name}@${packageInfo.version}`,
  ENV: process.env.NODE_ENV,
  PUBLIC_PATH: process.env.VUE_APP_PUBLIC_PATH,
  ROUTER_URL: process.env.VUE_APP_ROUTER_URL,
  API_URL: process.env.VUE_APP_API_URL,
  SSO: {
    AUTH_SERVER_URI: process.env.VUE_APP_AUTH_SERVER_URI,
    CLIENT_ID: 'back-office',
  },
  SENTRY: {
    ENV: process.env.VUE_APP_SENTRY_ENV,
    DSN: process.env.VUE_APP_SENTRY_DSN,
  },
  API: {
    EXTERNAL: process.env.VUE_APP_API_URL,
    AUTH: process.env.VUE_APP_API_URL_AUTH,
  },
};
