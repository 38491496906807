// module name
export const STORE_MODULE_PAYMENT_RATES = 'STORE_MODULE_PAYMENT_RATES';

// mutations
export const MUTATION_SET_PAYMENT_RATES = 'MUTATION_SET_PAYMENT_RATES';
export const MUTATION_SET_IS_LOADING = 'MUTATION_SET_IS_LOADING';

// actions
export const ACTION_FETCH_PAYMENT_RATES = 'ACTION_FETCH_PAYMENT_RATES';
export const ACTION_UPDATE_PAYMENT_RATES = 'ACTION_UPDATE_PAYMENT_RATES';
export const ACTION_CREATE_ADDITIONAL_RATE = 'ACTION_CREATE_ADDITIONAL_RATE';
export const UPDATE_CREATE_ADDITIONAL_RATE = 'UPDATE_CREATE_ADDITIONAL_RATE';
export const ACTION_CREATE_ACARD_RATE = 'ACTION_CREATE_ACARD_RATE';
export const ACTION_DELETE_ACARD_RATE = 'ACTION_DELETE_ACARD_RATE';

// getters
export const GETTER_PAYMENT_RATES = 'GETTER_PAYMENT_RATES';
export const GETTER_IS_LOADING = 'GETTER_IS_LOADING';
