/**
 * All routes available in application
 */
export default {
  ROOT: {
    PATH: '/',
    NAME: 'root',
  },
  ROLES_ASSIGNMENT: {
    PATH: '/roles-assignment',
    NAME: 'roles-assignment',
  },
  RATES: {
    PATH: '/rates',
    NAME: 'rates',
  },
  RATES_HISTORY: {
    PATH: 'history',
    NAME: 'rates-history',
  },
  PAYMENT_SYSTEMS: {
    PATH: '/payment-systems',
    NAME: 'payment-systems',
  },
  PAYMENT_RATES: {
    PATH: '/payment-rates',
    NAME: 'payment-rates',
  },
  PAYMENTS: {
    PATH: '/payments',
    NAME: 'payments',
  },
  NO_ACCESS: {
    PATH: '/no-access',
    NAME: 'no-access',
  },
  USERS: {
    PATH: '/users-management',
    NAME: 'users-management',
  },
  USER_DETAILS: {
    PATH: ':userId',
    NAME: 'user-details',
  },
};
