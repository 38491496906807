import { AppModule } from '@amarkets/atlas/esm/lib/app-module';
import { STORE_USERS } from './store/types';

const rootComponent = () => import(
  /* webpackChunkName: "module-users-management" */
  './components/module-root.vue'
);
const storeModule = () => import(
  /* webpackChunkName: "module-users-management-store" */
  './store'
);

export default {
  module: new AppModule(rootComponent, {
    props: {},
    storeAsyncFactory: storeModule,
    storeModuleName: STORE_USERS,
  }),
};
