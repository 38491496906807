<template>
  <app-container
    class="pt-7 pl-8 pr-8"
    :fluid="true"
  >
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
    >
      <app-spinner
        color="primary"
        size="xxl"
      />
    </div>
    <slot v-else />
  </app-container>
</template>

<script>
import { AppContainer, AppSpinner } from '@amarkets/ui-kit';

export default {
  name: 'app-page',
  components: {
    AppContainer,
    AppSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
