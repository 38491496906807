import { withVuexNamespace } from '@/lib/utils';
import {
  STORE_MODULE_SYMBOLS,
  ACTION_GET_AVAILABLE_SYMBOLS,
  GETTERS_AVAILABLE_SYMBOLS,
} from '@/plugins/store/modules/symbols/types';

const factory = ({ mapGetters, mapActions }) => ({
  computed: {
    ...mapGetters({
      getSymbols: GETTERS_AVAILABLE_SYMBOLS,
    }),
  },
  methods: {
    ...mapActions({
      fetchSymbols: ACTION_GET_AVAILABLE_SYMBOLS,
    }),
  },
});

export default withVuexNamespace(STORE_MODULE_SYMBOLS, factory);
