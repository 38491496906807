<template>
  <app-page :loading="isLoading">
    <module-payment-rates
      :payment-systems="getPaymentSystems"
      :payment-methods="getPaymentMethods"
      :currencies="getCurrencies"
      :countries="getCountries"
      :is-loading-payment-systems="isLoading"
    />
  </app-page>
</template>

<script>
import { AppPage } from '@/components/app-page';
import { facadePaymentSystems } from '@/mixins';

export default {
  name: 'payment-rates-page',
  components: {
    AppPage,
  },
  mixins: [facadePaymentSystems],
  mounted() {
    Promise.all([
      this.fetchPaymentSystems(),
      this.fetchPaymentMethods(),
      this.fetchCountries(),
      this.fetchCurrencies(),
    ]);
  },
  beforeDestroy() {
    this.setStateToDefault();
  },
};
</script>
