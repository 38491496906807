<template>
  <div class="d-flex justify-center align-center">
    <app-text class-name="font-weight-bold">
      No access
    </app-text>
  </div>
</template>

<script>
import { AppText } from '@amarkets/ui-kit';

export default {
  name: 'no-access-page',
  components: {
    AppText,
  },
};
</script>
