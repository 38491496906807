<template>
  <div class="app-stub d-flex justify-center align-center">
    <app-spinner
      color="primary"
      size="xxl"
    />
  </div>
</template>

<script>
import { AppSpinner } from '@amarkets/ui-kit';

export default {
  name: 'app-stub',
  components: {
    AppSpinner,
  },
};
</script>

<style lang="scss" scoped>
  @import 'app-stub';
</style>
