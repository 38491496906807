export default class TokenService {
  _authApi;
  _onTokenUpdate;
  _accessToken;
  _refreshToken;

  constructor({
    authApi,
    onTokenUpdate,
    accessToken = '',
    refreshToken = '',
  }) {
    this._authApi = authApi;
    this._onTokenUpdate = onTokenUpdate;
    this._accessToken = accessToken;
    this._refreshToken = refreshToken;
  }

  get accessToken() {
    return this._accessToken;
  }

  get refreshToken() {
    return this._refreshToken;
  }

  async updateToken() {
    if (!this._refreshToken) {
      throw new Error('cannot updateToken with empty refreshToken');
    }

    try {
      const { accessToken, refreshToken } = await this._authApi.updateToken(this._refreshToken);
      this._accessToken = accessToken;
      this._refreshToken = refreshToken;

      if (this._onTokenUpdate) {
        this._onTokenUpdate(accessToken, refreshToken);
      }
      return Promise.resolve({ accessToken });
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
