import { AppModule } from '@amarkets/atlas/esm/lib/app-module';
import { STORE_MODULE_PAYMENT_RATES } from './store/types';

const rootComponent = () => import(
  /* webpackChunkName: "module-payment-rates" */
  './components/module-root/module-root.vue'
);
const storeModule = () => import(
  /* webpackChunkName: "module-payment-rates-store" */
  './store'
);

const props = {
  paymentSystems: {
    type: Array,
    default: () => [],
  },
  isLoadingPaymentSystems: {
    type: Boolean,
    default: false,
  },
  paymentMethods: {
    type: Array,
    default: () => [],
  },
  currencies: {
    type: Array,
    default: () => [],
  },
  countries: {
    type: Array,
    default: () => [],
  },
};

const propsProviderFactory = (moduleRoot) => ({
  getPaymentSystems: () => moduleRoot.paymentSystems,
  getPaymentMethods: () => moduleRoot.paymentMethods,
  getCurrencies: () => moduleRoot.currencies,
  getCountries: () => moduleRoot.countries,
  isLoadingPaymentSystems: () => moduleRoot.isLoadingPaymentSystems,
});

const eventsFactory = () => {};

export default {
  module: new AppModule(rootComponent, {
    props,
    storeAsyncFactory: storeModule,
    storeModuleName: STORE_MODULE_PAYMENT_RATES,
    eventsFactory,
    propsProviderFactory,
  }),
};
