import {
  MUTATION_SET_AUTH_USER,
  MUTATION_SET_APP_INIT_STATE,
} from './types';

export default {
  [MUTATION_SET_AUTH_USER](state, authUser) {
    state.authUser = authUser;
  },
  [MUTATION_SET_APP_INIT_STATE](state, initState) {
    state.appInitState = initState;
  },
};
