<template>
  <app-navigation-drawer
    class="app-navigation pl-2 pr-2"
    :app="true"
    :right="false"
    :width="300"
    :value="value"
    @input="$emit('input', $event)"
  >
    <app-title
      :level="3"
      class-name="pl-4 mt-6 mb-2"
    >
      MENU
    </app-title>
    <v-list-item
      v-for="link in paymentLinks"
      :key="link.title"
    >
      <v-list-item-content>
        <v-list-item-title>
          <app-link
            content-classes="font-weight-bold"
            text-variant="caption"
            :to="link.to"
          >
            {{ link.title }}
          </app-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list dense>
      <app-title
        :level="3"
        class-name="pl-4 mt-6 mb-2"
      >
        ADMIN
      </app-title>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <router-link :to="{ name: userManagementLink.name }">
              {{ userManagementLink.title }}
            </router-link>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <app-title
        :level="3"
        class-name="pl-4 mt-6 mb-2"
      >
        DEPARTMENTS
      </app-title>
      <v-list-group
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        no-action
      >
        <template #activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="child in item.items"
          :key="child.title"
        >
          <v-list-item-content>
            <a
              v-if="child.link"
              :href="child.link"
            >
              <v-list-item-title v-text="child.title" />
            </a>
            <v-list-item-title
              v-else
              v-text="child.title"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <app-title
        :level="3"
        class-name="pl-4 mt-6 mb-2"
      >
        FINANCE
      </app-title>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <a href="https://abc.amarkets.org/active_admin">Active admin</a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group
        v-for="item in items3"
        :key="item.title"
        v-model="item.active"
        no-action
      >
        <template #activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.items"
          :key="child.title"
        >
          <v-list-item-content>
            <a
              v-if="child.link"
              :href="child.link"
            >
              <v-list-item-title v-text="child.title" />
            </a>
            <v-list-item-title
              v-else
              v-text="child.title"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </app-navigation-drawer>
</template>

<script>
import { AppNavigationDrawer, AppLink, AppTitle } from '@amarkets/ui-kit';
import { APP_ROUTES, LEGACY_LINKS } from '@/constants';

const {
  SPACES,
  FINANCIAL_OPERATIONS,
  REMUNERATION_RULES,
  REWARDS,
  REPORTS,
  FINANCIAL_SYSTEM,
  REQUESTS,
} = LEGACY_LINKS;

export default {
  name: 'app-navigation',
  components: {
    AppNavigationDrawer,
    AppLink,
    AppTitle,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      return [
        {
          action: 'mdi-square-rounded',
          title: 'Spaces',
          items: [
            { title: 'Client', link: SPACES.CLIENT },
            { title: 'Manager', link: SPACES.MANAGER },
            { title: 'Partnership manager', link: SPACES.PARTNERSHIP_MANAGER },
            { title: 'Partnership admin', link: SPACES.PARTNERSHIP_ADMIN },
            { title: 'Dealing', link: SPACES.DEALING },
            { title: 'Marketing', link: SPACES.MARKETING },
          ],
        },
      ];
    },
    items3() {
      return [
        {
          action: 'mdi-square-rounded',
          title: 'Financial operations',
          items: [
            { title: 'Request for payments', link: FINANCIAL_OPERATIONS.REQUEST_FOR_PAYMENTS },
            { title: 'Request for withdrawals', link: FINANCIAL_OPERATIONS.REQUEST_FOR_WITHDRAWALS },
            { title: 'Transaction reports', link: FINANCIAL_OPERATIONS.TRANSACTION_REPORTS },
            { title: 'Financial diagrams', link: FINANCIAL_OPERATIONS.FINANCIAL_DIAGRAMS },
            { title: 'Payment system reports', link: FINANCIAL_OPERATIONS.PAYMENT_SYSTEM_REPORTS },
            { title: 'Payment system reports2', link: FINANCIAL_OPERATIONS.PAYMENT_SYSTEM_REPORTS2 },
            { title: 'Daily stats', link: FINANCIAL_OPERATIONS.DAILY_STATS },
            { title: 'Finish withdrawals report', link: FINANCIAL_OPERATIONS.FINISH_WITHDRAWALS_REPORT },
          ],
        },
        {
          action: 'mdi-square-rounded',
          title: 'Remuneration rules',
          items: [
            { title: 'Template rules', link: REMUNERATION_RULES.TEMPLATE_RULES },
            { title: 'Rewards rates IB', link: REMUNERATION_RULES.REWARDS_RATES_IB },
            { title: 'Rewards rates Web', link: REMUNERATION_RULES.REWARDS_RATES_WEB },
          ],
        },
        {
          action: 'mdi-square-rounded',
          title: 'Rewards',
          items: [
            { title: 'Partner', link: REWARDS.PARTNER },
            { title: 'Partner bonuses', link: REWARDS.PARTNER_BONUSES },
            { title: 'Manage of bonus orders', link: REWARDS.MANAGE_OF_BONUS_ORDERS },
            { title: 'Manage of welcome bonuses', link: REWARDS.MANAGE_OF_WELCOME_BONUSES },
            { title: 'Manage of insurance bonuses', link: REWARDS.MANAGE_OF_INSURANCE_BONUSES },
          ],
        },
        {
          action: 'mdi-square-rounded',
          title: 'Reports',
          items: [
            { title: 'Withdrawals', link: REPORTS.WITHDRAWALS },
            { title: 'Manage of bonus orders', link: REPORTS.MANAGE_OF_BONUS_ORDERS },
            { title: 'First replenishment', link: REPORTS.FIRST_REPLENISHMENT },
            { title: 'First replenishment for 100', link: REPORTS.FIRST_REPLENISHMENT_FOR_100 },
            { title: 'Analyst motivation reports', link: REPORTS.ANALYST_MOTIVATION_REPORTS },
            { title: 'Conversions', link: REPORTS.CONVERSIONS },
            { title: 'Conversion operations', link: REPORTS.CONVERSION_OPERATIONS },
            { title: 'Common glasses', link: REPORTS.COMMON_GLASSES },
            { title: 'Managers rating', link: REPORTS.MANAGERS_RATING },
            { title: 'Managers rating settings', link: REPORTS.MANAGERS_RATING_SETTINGS },
            { title: 'Low profitability of withdrawals', link: REPORTS.LOW_PROFITABILITY_OF_WITHDRAWALS },
            { title: 'Loyalty bonus rewards', link: REPORTS.LOYALTY_BONUS_REWARDS },
            { title: 'No bonus rewards', link: REPORTS.NO_BONUS_REWARDS },
          ],
        },
        {
          action: 'mdi-square-rounded',
          title: 'Financial system',
          items: [
            { title: 'Fin System', link: FINANCIAL_SYSTEM.FIN_SYSTEM },
            { title: 'Movement of funds', link: FINANCIAL_SYSTEM.MOVEMENT_OF_FUNDS },
            { title: 'Bitcoin reports', link: FINANCIAL_SYSTEM.BITCOIN_REPORTS },
            { title: 'DDF diffs', link: FINANCIAL_SYSTEM.DDF_DIFFS },
            { title: 'Bonus flow report', link: FINANCIAL_SYSTEM.BONUS_FLOW_REPORT },
            { title: 'Clients online', link: FINANCIAL_SYSTEM.CLIENTS_ONLINE },
            { title: 'Withdrawal commission funds', link: FINANCIAL_SYSTEM.WITHDRAWAL_COMMISSION_FUNDS },
          ],
        },
        {
          action: 'mdi-square-rounded',
          title: 'Requests',
          items: [
            { title: 'Verifications', link: REQUESTS.VERIFICATIONS },
            { title: 'PS verification', link: REQUESTS.PS_VERIFICATION },
            { title: 'PS Verification speed', link: REQUESTS.PS_VERIFICATION_SPEED },
            { title: 'Bonus verifications', link: REQUESTS.BONUS_VERIFICATIONS },
            { title: 'Verifications traffic light', link: REQUESTS.VERIFICATIONS_TRAFFIC_LIGHT },
            { title: 'Verification speed', link: REQUESTS.VERIFICATION_SPEED },
            { title: 'Compensations', link: REQUESTS.COMPENSATIONS },
          ],
        },
      ];
    },
    paymentLinks() {
      return [
        { title: 'Payment systems', to: APP_ROUTES.PAYMENT_SYSTEMS.NAME },
        { title: 'Payment rates', to: APP_ROUTES.PAYMENT_RATES.NAME },
        { title: 'Payments', to: APP_ROUTES.PAYMENTS.NAME },
        { title: 'Rates', to: APP_ROUTES.RATES.NAME },
      ];
    },
    userManagementLink() {
      return {
        title: 'Users',
        name: APP_ROUTES.USERS.NAME,
      };
    },
  },
};
</script>
