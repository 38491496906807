import { AppModule } from '@amarkets/atlas/esm/lib/app-module';
import { STORE_MODULE_PAYMENTS } from './store/types';

const rootComponent = () => import(
  /* webpackChunkName: "module-payments" */
  './components/module-root/module-root.vue'
);
const storeModule = () => import(
  /* webpackChunkName: "module-payments-store" */
  './store'
);

const props = {};

const propsProviderFactory = () => ({});

const eventsFactory = () => {};

export default {
  module: new AppModule(rootComponent, {
    props,
    storeAsyncFactory: storeModule,
    storeModuleName: STORE_MODULE_PAYMENTS,
    eventsFactory,
    propsProviderFactory,
  }),
};
