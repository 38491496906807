<template>
  <v-app-bar
    class="app-bar"
    :app="true"
    :hide-on-scroll="true"
    :rounded="true"
    color="white"
  >
    <app-button-icon
      icon="chevron-left"
      :class="toggleIconClasses"
      @click="handleClick"
    />
    <div class="ml-auto">
      <b>username:</b> {{ authorizedUser.name }}
    </div>
  </v-app-bar>
</template>

<script>
import { AppButtonIcon } from '@amarkets/ui-kit';

export default {
  name: 'app-bar',
  components: {
    AppButtonIcon,
  },
  props: {
    authorizedUser: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isIconReversed: false,
  }),
  computed: {
    toggleIconClasses() {
      const baseClass = 'app-bar__toggle-icon';

      return [
        baseClass,
        this.isIconReversed ? `${baseClass}--reversed` : null,
      ];
    },
  },
  methods: {
    handleClick() {
      this.isIconReversed = !this.isIconReversed;
      this.$emit('toggle-icon-click');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'app-bar';
</style>
