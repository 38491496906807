// module name
export const STORE_MODULE_RATES_MANAGEMENT = 'STORE_MODULE_RATES_MANAGEMENT';

// actions
export const ACTION_GET_AVAILABLE_SYMBOLS = 'ACTION_GET_AVAILABLE_SYMBOLS';
export const ACTION_GET_SELECTED_RATE = 'ACTION_GET_SELECTED_RATE';
export const ACTION_UPDATE_SELECTED_RATE_COMMISSION = 'ACTION_UPDATE_SELECTED_RATE_COMMISSION';

// mutations
export const MUTATION_SET_AVAILABLE_SYMBOLS = 'MUTATION_SET_AVAILABLE_SYMBOLS';
export const MUTATION_SET_SELECTED_RATE = 'MUTATION_SET_SELECTED_RATE';

// getters
export const GETTERS_AVAILABLE_SYMBOLS = 'GETTERS_AVAILABLE_SYMBOLS';
export const GETTERS_SELECTED_RATE = 'GETTERS_SELECTED_RATE';
