export default {
  injectAuthHeader: (getAccessToken) => (reqConfig) => {
    const { headers, ...restConfig } = reqConfig;
    return {
      ...restConfig,
      headers: {
        ...headers,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    };
  },
  test: (reqConfig) => reqConfig,
};
