// module name
export const STORE_USERS = 'STORE_USERS';

// actions
export const ACTION_FETCH_USERS = 'ACTION_FETCH_USERS';
export const ACTION_FETCH_ROLES = 'ACTION_FETCH_ROLES';
export const ACTION_FETCH_USER_BY_ID = 'ACTION_FETCH_USER_BY_ID';
export const ACTION_UPDATE_USER_ROLES = 'ACTION_UPDATE_USER_ROLES';
// mutations
export const MUTATION_SET_USERS = 'MUTATION_SET_USERS';
export const MUTATION_SET_IS_LOADING = 'MUTATION_SET_IS_LOADING';
export const MUTATION_SET_FILTERS = 'MUTATION_SET_FILTERS';
export const MUTATION_SET_ROLES = 'MUTATION_SET_ROLES';
export const MUTATION_SET_EDITABLE_USER = 'MUTATION_SET_EDITABLE_USER';
export const MUTATION_SET_DEFAULT_STATE = 'MUTATION_SET_DEFAULT_STATE';
// getters
export const GETTERS_USERS = 'GETTERS_USERS';
export const GETTERS_FILTERS = 'GETTERS_FILTERS';
export const GETTERS_ROLES = 'GETTERS_ROLES';
export const GETTERS_IS_LOADING_STATUS = 'GETTERS_IS_LOADING_STATUS';
export const GETTERS_SELECTED_USER = 'GETTERS_SELECTED_USER';
