import {
  MUTATION_SET_PAYMENT_SYSTEMS,
  MUTATION_SET_IS_LOADING,
  MUTATION_SET_COUNTRIES,
  MUTATION_SET_CURRENCIES,
  MUTATION_SET_PAYMENT_METHODS,
} from './types';

export default {
  [MUTATION_SET_PAYMENT_SYSTEMS](state, paymentSystems) {
    state.paymentSystems = paymentSystems;
  },
  [MUTATION_SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MUTATION_SET_PAYMENT_METHODS](state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  [MUTATION_SET_CURRENCIES](state, currencies) {
    state.currencies = currencies;
  },
  [MUTATION_SET_COUNTRIES](state, countries) {
    state.countries = countries;
  },
};
