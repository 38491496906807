import { APP_INIT_STATES } from '@/constants';
import {
  ACTION_AUTH_USER,
  ACTION_LOGOUT_USER,
  ACTION_INIT_APP,
  MUTATION_SET_AUTH_USER,
  MUTATION_SET_APP_INIT_STATE,
} from './types';

export default function ({ authService, raiseError }) {
  return {
    async [ACTION_AUTH_USER]({ commit }, accessToken) {
      try {
        const authUser = await authService.authorize(accessToken);
        commit(MUTATION_SET_AUTH_USER, authUser);
      } catch (e) {
        raiseError(new Error('test error'), { test: 1 });
        throw e;
      }
    },
    async [ACTION_LOGOUT_USER]({ commit }, refreshToken) {
      try {
        await authService.logout(refreshToken);
        commit(MUTATION_SET_AUTH_USER, null);
      } catch (e) {
        raiseError(e);
      }
    },
    async [ACTION_INIT_APP]({ dispatch, commit }, accessToken) {
      try {
        await dispatch(ACTION_AUTH_USER, accessToken);
        commit(MUTATION_SET_APP_INIT_STATE, APP_INIT_STATES.SUCCESS);
      } catch (e) {
        console.error('error while app init');
        commit(MUTATION_SET_APP_INIT_STATE, APP_INIT_STATES.FAIL);
      }
    },
  };
}
