<template>
  <app-page class="currency-root-page">
    <app-tabs
      :items="$options.tabItems"
      :current-route-name="$route.name"
    />
    <router-view />
  </app-page>
</template>

<script>
import { AppTabs } from '@amarkets/ui-kit';
import { APP_ROUTES } from '@/constants';
import { AppPage } from '@/components/app-page';

export default {
  name: 'currency-root-page',
  components: {
    AppPage,
    AppTabs,
  },
  tabItems: [
    {
      text: 'Rates current',
      to: { name: APP_ROUTES.RATES.NAME },
    },
    {
      text: 'Rates history',
      to: { name: APP_ROUTES.RATES_HISTORY.NAME },
    },
  ],
};
</script>
