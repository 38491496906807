<template>
  <app-fade-transition :appear="true">
    <div
      id="app"
      class="app"
    >
      <layout-main />
    </div>
  </app-fade-transition>
</template>

<script>
import { AppFadeTransition } from '@amarkets/ui-kit';
import { LayoutMain } from '@/layouts/main';

export default {
  name: 'app',
  components: {
    AppFadeTransition,
    LayoutMain,
  },
};
</script>

<style lang="scss">
  @import 'app';
</style>
