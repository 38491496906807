// module name
export const STORE_MODULE_PAYMENTS = 'STORE_MODULE_PAYMENTS';

// mutations
export const MUTATION_SET_PAYMENTS = 'MUTATION_SET_PAYMENTS';
export const MUTATION_SET_IS_LOADING = 'MUTATION_SET_IS_LOADING';
export const MUTATION_SET_STATE_TO_DEFAULT = 'MUTATION_SET_STATE_TO_DEFAULT';
export const MUTATION_SET_PAGINATION = 'MUTATION_SET_PAGINATION';

// actions
export const ACTION_FETCH_PAYMENTS = 'ACTION_FETCH_PAYMENTS';

// getters
export const GETTER_PAYMENTS = 'GETTER_PAYMENTS';
export const GETTER_PAGINATION = 'GETTER_PAGINATION';
export const GETTER_IS_LOADING = 'GETTER_IS_LOADING';
