// module name
export const STORE_MODULE_SYMBOL_RATES = 'STORE_MODULE_SYMBOL_RATES';

// actions
export const ACTION_FETCH_SYMBOL_RATES = 'ACTION_FETCH_SYMBOL_RATES';
export const ACTION_FETCH_CURRENT_RATES = 'ACTION_FETCH_CURRENT_RATES';

// mutations
export const MUTATION_SET_SYMBOL_RATES = 'MUTATION_SET_SYMBOL_RATES';

// getters
export const GETTER_SYMBOL_RATES = 'GETTER_SYMBOL_RATES';
export const GETTER_SYMBOL_PAGINATION = 'GETTER_SYMBOL_PAGINATION';
