import { PaymentService } from '@/services';

import state from './state';
import createActions from './actions';
import mutations from './mutations';
import getters from './getters';

export default function (context) {
  const { apiServices: { processingApi }, raiseError } = context;

  const paymentService = new PaymentService({ processingApi, raiseError });

  const actions = createActions(paymentService);

  return {
    namespaced: true,
    state: () => state,
    actions,
    mutations,
    getters,
  };
}
