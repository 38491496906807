import Vue from 'vue';

import { ErrorHandler } from '@amarkets/atlas/esm/lib/error-handler';
import { HttpErrorParser } from '@amarkets/atlas/esm/lib/http-error-parser';

import { ErrorBusService, TokenSyncService } from '@/services';
import { parseAccessToken } from '@/utils';

import { CONFIG, ERROR_KEYS_BY_STATUS_CODE } from '../constants';

import createStore from '../plugins/store';
import createRouter from '../plugins/router';
import createVuetify from '../plugins/vuetify';
import createUiNotifier from '../plugins/vue-notification';
import createSentry from '../plugins/sentry';

import createHttpClient from './create-http-client';
import createApiServices from './create-api-services';
import registerStoreModules from './register-store-modules';
import registerFeatureModules from './register-feature-modules';

export default function ({ appConfig }) {
  const config = CONFIG;
  const tokenSyncService = new TokenSyncService(appConfig.ENV);
  const errorBus = new ErrorBusService();

  const httpClient = createHttpClient({
    ...tokenSyncService.tokens,
    appConfig,
    onTokenUpdate: tokenSyncService.sync.bind(tokenSyncService),
  });

  const apiServices = createApiServices(httpClient, appConfig);
  const store = createStore();
  const router = createRouter({ appConfig, ...parseAccessToken(tokenSyncService.tokens) });
  const vuetify = createVuetify();
  const uiNotifier = createUiNotifier();

  const sentry = createSentry({
    dsn: config.SENTRY.DSN,
    release: config.APP_RELEASE,
    sampleRate: config.SENTRY.ENV === 'development' ? 1.0 : 0.5,
    attachStacktrace: true,
    logErrors: true,
    environment: config.SENTRY.ENV,
  });

  const httpErrorParser = new HttpErrorParser({
    defaultMsg: ERROR_KEYS_BY_STATUS_CODE.DEFAULT,
    msgByStatusCode: ERROR_KEYS_BY_STATUS_CODE.BY_STATUS_CODE,
  });

  const errorHandler = new ErrorHandler({
    httpErrorParser,
    errorTracker: sentry,
    uiNotifier,
    defaultErrorMsg: ERROR_KEYS_BY_STATUS_CODE.DEFAULT,
    logger: {
      error: console.error,
    },
  });

  const ctx = {
    store,
    apiServices,
    raiseError: errorBus.raiseError.bind(errorBus),
    router,
    vuetify,
    errorHandler,
  };

  // core store modules registration
  registerStoreModules({ ...ctx, tokenSyncService });

  // feature modules registration
  registerFeatureModules(Vue, ctx);

  return {
    errorBus,
    store,
    router,
    vuetify,
    tokenSyncService,
  };
}
