import {
  ACTION_FETCH_PAYMENT_SYSTEMS,
  ACTION_CREATE_PAYMENT_SYSTEMS,
  ACTION_UPDATE_PAYMENT_SYSTEMS,
  ACTION_SET_STATE_TO_DEFAULT,
  MUTATION_SET_PAYMENT_SYSTEMS,
  MUTATION_SET_IS_LOADING,
  ACTION_FETCH_COUNTRIES,
  ACTION_FETCH_CURRENCIES,
  ACTION_FETCH_PAYMENT_METHODS,
  MUTATION_SET_PAYMENT_METHODS,
  MUTATION_SET_COUNTRIES,
  MUTATION_SET_CURRENCIES,
} from './types';

export default function (paymentSystemService) {
  return {
    async [ACTION_FETCH_PAYMENT_SYSTEMS]({ commit }) {
      commit(MUTATION_SET_IS_LOADING, true);

      await paymentSystemService.getPaymentSystems();

      commit(MUTATION_SET_PAYMENT_SYSTEMS, paymentSystemService.paymentSystems);
      commit(MUTATION_SET_IS_LOADING, false);
    },
    async [ACTION_UPDATE_PAYMENT_SYSTEMS]({ commit }, payload) {
      await paymentSystemService.updatePaymentSystem(payload);
      commit(MUTATION_SET_PAYMENT_SYSTEMS, []);
    },
    async [ACTION_CREATE_PAYMENT_SYSTEMS]({ commit }, payload) {
      await paymentSystemService.createPaymentSystem(payload);
      commit(MUTATION_SET_PAYMENT_SYSTEMS, []);
    },
    async [ACTION_FETCH_PAYMENT_METHODS]({ commit }) {
      await paymentSystemService.getPaymentMethods();
      commit(MUTATION_SET_PAYMENT_METHODS, paymentSystemService.paymentMethods);
    },
    async [ACTION_FETCH_CURRENCIES]({ commit }) {
      await paymentSystemService.getCurrencies();
      commit(MUTATION_SET_CURRENCIES, paymentSystemService.currencies);
    },
    async [ACTION_FETCH_COUNTRIES]({ commit }) {
      await paymentSystemService.getCountries();
      commit(MUTATION_SET_COUNTRIES, paymentSystemService.countries);
    },
    [ACTION_SET_STATE_TO_DEFAULT]({ commit }) {
      commit(MUTATION_SET_PAYMENT_SYSTEMS, []);
    },
  };
}
