export default {
  BY_STATUS_CODE: {
    401: 'You are not authorized to view this page. Please go through the authorization process.',
    403: 'Access denied. You are not authorized to view this page.',
    404: 'Page not found or never existed.',
    410: 'This page no longer exists. Please contact our customer service.',
    429: 'Too Many Requests',
    500: 'Internal server error',
    502: 'Bad gateway. We already know about this issue and we are trying to fix it. Please, try again later.',
  },
  DEFAULT: 'Sorry, something went wrong. We are working on it and we will get it fixed as soon as we can.',
};
