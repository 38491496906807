import { VuexFacadeBase } from '@amarkets/atlas/esm/lib/vuex-facade-base';
import {
  STORE_MODULE_SYMBOL_RATES,
  GETTER_SYMBOL_RATES,
  GETTER_SYMBOL_PAGINATION,
  ACTION_FETCH_SYMBOL_RATES,
  ACTION_FETCH_CURRENT_RATES,
} from '../store/types';

export default class FacadeSymbolRates extends VuexFacadeBase {
  constructor(store) {
    super(store, STORE_MODULE_SYMBOL_RATES);
  }

  get symbolRates() {
    return this.getters(GETTER_SYMBOL_RATES);
  }

  get symbolPagination() {
    return this.getters(GETTER_SYMBOL_PAGINATION);
  }

  fetchSymbolRates(payload) {
    return this.dispatch(ACTION_FETCH_SYMBOL_RATES, payload);
  }

  fetchCurrentRates(payload) {
    return this.dispatch(ACTION_FETCH_CURRENT_RATES, payload);
  }
}
