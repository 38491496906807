import {
  GETTER_PAYMENT_SYSTEMS,
  GETTER_IS_LOADING,
  GETTER_COUNTRIES,
  GETTER_CURRENCIES,
  GETTER_PAYMENT_METHODS,
} from './types';

export default {
  [GETTER_PAYMENT_SYSTEMS]: ({ paymentSystems }) => paymentSystems,
  [GETTER_PAYMENT_METHODS]: ({ paymentMethods }) => paymentMethods,
  [GETTER_CURRENCIES]: ({ currencies }) => currencies,
  [GETTER_COUNTRIES]: ({ countries }) => countries,
  [GETTER_IS_LOADING]: ({ isLoading }) => isLoading,
};
