import { AuthorizationService } from '@/services';
import state from './state';
import createActions from './actions';
import mutations from './mutations';
import getters from './getters';

export default function (context) {
  const {
    tokenSyncService,
    raiseError,
    apiServices: { authApi },
  } = context;

  const authService = new AuthorizationService(authApi, tokenSyncService);

  return {
    namespaced: true,
    state: () => state,
    actions: createActions({ authService, raiseError }),
    mutations,
    getters,
  };
}
