import { USER_ROLES, APP_ROUTES } from '@/constants';

export function accessGuard(userRoles) {
  const usersWithAccess = [USER_ROLES.ADMIN, USER_ROLES.MANAGER, USER_ROLES.FINANCIAL_OPERATOR, USER_ROLES.TELLER];

  return (to, from, next) => {
    if (!userRoles.some((userRole) => usersWithAccess.includes(userRole)) && to.name !== APP_ROUTES.NO_ACCESS.NAME) {
      return next({ name: APP_ROUTES.NO_ACCESS.NAME });
    }
    return next();
  };
}
