import { Symbol } from '@/shared/models/symbol';

export default class SymbolService {
  _exchangeApi;

  constructor({ exchangeApi, raiseError }) {
    this._exchangeApi = exchangeApi;
    this._raiseError = raiseError;
  }

  async call(commit, savedAvailableSymbols = []) {
    if (!savedAvailableSymbols.length) {
      try {
        const availableSymbols = await this._exchangeApi.getAvailableSymbols(SymbolService._wrapIntoModel);
        commit(availableSymbols);
      } catch (e) {
        this._raiseError(e);
      }
    }
  }

  static _wrapIntoModel(opts) {
    return new Symbol(opts);
  }
}
