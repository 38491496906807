import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import { accessGuard } from './guards/access-guard';

Vue.use(VueRouter);

export default function ({ appConfig, userRoles }) {
  const router = new VueRouter({
    mode: 'history',
    base: appConfig.ROUTER_URL,
    routes,
  });

  router.beforeEach(accessGuard(userRoles));

  return router;
}
