// module name
export const STORE_MODULE_GLOBAL = 'STORE_MODULE_GLOBAL';

// actions
export const ACTION_AUTH_USER = 'ACTION_AUTH_USER';
export const ACTION_LOGOUT_USER = 'ACTION_LOGOUT_USER';
export const ACTION_INIT_APP = 'ACTION_INIT_APP';

// mutations
export const MUTATION_SET_AUTH_USER = 'MUTATION_SET_AUTH_USER';
export const MUTATION_SET_APP_INIT_STATE = 'MUTATION_SET_APP_INIT_STATE';

// getters
export const GETTER_AUTH_USER = 'GETTER_AUTH_USER';
export const GETTER_APP_INIT_STATE = 'GETTER_APP_INIT_STATE';
