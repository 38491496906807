// module name
export const STORE_MODULE_SYMBOLS = 'STORE_MODULE_SYMBOLS';

// actions
export const ACTION_GET_AVAILABLE_SYMBOLS = 'ACTION_GET_AVAILABLE_SYMBOLS';

// mutations
export const MUTATION_SET_AVAILABLE_SYMBOLS = 'MUTATION_SET_AVAILABLE_SYMBOLS';

// getters
export const GETTERS_AVAILABLE_SYMBOLS = 'GETTERS_AVAILABLE_SYMBOLS';
